// The reviver and replacers functions exported here use
// encoder and decoder functions support :
//   - All JSON safe objects
//   - Sets
export const ARRAY_INDICATOR_VALUE = '~ENCODED_SET~';

function replaceSets(_key: string, value: unknown): unknown {
  return value instanceof Set ? [...value, ARRAY_INDICATOR_VALUE] : value;
}

function reviveSets(_key: string, value: unknown): unknown {
  return Array.isArray(value) &&
    value[value.length - 1] === ARRAY_INDICATOR_VALUE
    ? new Set(value.slice(0, -1))
    : value;
}

export function encodeAsString(value: unknown): string {
  return JSON.stringify(value, replaceSets);
}

export function decodeString(value: string): any {
  return JSON.parse(value, reviveSets);
}

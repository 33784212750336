import { Layout } from '@crowley/enterprise-react-component-library';
import { OktaProvider } from '../components/OktaContext';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FilterProvider } from '../components/FilterContext';
import '../components/tailwind.css';
import { VesselProvider } from '../components/VesselContext';
import { useRouter } from 'next/router';
import { MapProvider } from 'react-map-gl';
import { HOME_PAGE_URL } from '../utils/routes';
import { useState } from 'react';

function CustomApp({ Component, pageProps }: AppProps) {
  const { push } = useRouter();
  const [userName, setUserName] = useState('');

  return (
    <>
      <Head>
        <title>Vessel Management</title>
      </Head>
      <Layout
        masthead={{
          title: 'Ship Management Platform',
          onLogoClick: () => push(HOME_PAGE_URL),
          name: userName
        }}
      >
        <OktaProvider setUserName={setUserName} userName={userName}>
          <FilterProvider>
            <VesselProvider>
              <MapProvider>
                <Component {...pageProps} />
              </MapProvider>
            </VesselProvider>
          </FilterProvider>
        </OktaProvider>
      </Layout>
    </>
  );
}

export default CustomApp;

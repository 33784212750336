import { useEffect, useRef } from 'react';

// A custom hook for executing a provided callback on a set interval.
export function useInterval(callback: () => void, interval: number) {
  const callbackRef = useRef<() => void>(() =>
    console.error(
      'SOMETHING WENT WRONG:',
      'the interval callbackRef was not set'
    )
  );

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const intervalFun = () => {
      callbackRef.current();
    };

    const intervalId = setInterval(intervalFun, interval);

    return () => clearInterval(intervalId);
  }, [interval]);
}

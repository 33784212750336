import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AuthState, OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { useRouter } from 'next/router';
import { LOGIN_PAGE_URL, NOT_FOUND_PAGE_URL } from '../utils/routes';

export interface OktaState {
  oktaAuth: OktaAuth | null;
  authState: AuthState | null;
  setAuthState: Dispatch<SetStateAction<AuthState | null>>;
}

interface OktaProviderProps {
  setUserName: Dispatch<SetStateAction<string>>;
  userName: string;
}

export const OktaContext = React.createContext<OktaState>({} as OktaState);

export const useOktaAuth = () => React.useContext(OktaContext);

export const OktaProvider: React.FC<OktaProviderProps> = ({
  children,
  userName,
  setUserName
}) => {
  const { push, pathname, asPath } = useRouter();
  const [oktaAuth, setOktaAuth] = useState<OktaAuth | null>(null);
  const [authState, setAuthState] = useState<AuthState | null>(() => {
    if (!oktaAuth) {
      return null;
    }

    return oktaAuth.authStateManager.getAuthState();
  });

  useEffect(() => {
    // Setup OktaAuth in useEffect so it does not run on server
    const config: OktaAuthOptions = {
      issuer: process.env.NEXT_PUBLIC_OKTA_DOMAIN,
      clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
      redirectUri: `${window.location.origin}${LOGIN_PAGE_URL}`,
      tokenManager: {
        storage: 'sessionStorage'
      },
      pkce: true,
      devMode: process.env.NEXT_PUBLIC_OKTA_DEV_MODE === 'true'
    };

    setOktaAuth(new OktaAuth(config));
  }, []);

  /**
   * Hook for setting the current url in okta
   */
  useEffect(() => {
    // Store where the user came from, ignore the login route
    /**
     * Note: There is a rare instance in desktop chrome and always in iOS safari
     * where the pathname is not same as window.location.href which saves login as the redirect url causing user to be stuck.
     * For this instance we are using `${window.location.protocol}//${window.location.host}${asPath}`
     * We are using `asPath` so all the query params are also included
     */
    if (pathname !== LOGIN_PAGE_URL) {
      const currentUrl = `${window.location.protocol}//${window.location.host}${asPath}`;
      oktaAuth?.setOriginalUri(currentUrl);
    }
  }, [pathname, asPath, oktaAuth]);

  /**
   * Hook for redirecting when user is not authenticated
   */
  useEffect(() => {
    // If user is not authenticated redirect to login page
    if (
      !authState?.isAuthenticated &&
      pathname !== LOGIN_PAGE_URL &&
      pathname !== NOT_FOUND_PAGE_URL
    ) {
      push(LOGIN_PAGE_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated, pathname]);

  /**
   * Hook to keep authState in sync with OktaAuth
   */
  useEffect(() => {
    if (!oktaAuth) {
      return;
    }
    const handler = (state: AuthState) => {
      setAuthState(state);
    };

    oktaAuth.authStateManager.subscribe(handler);
    oktaAuth.start();

    return () => {
      oktaAuth.authStateManager.unsubscribe(handler);
      oktaAuth.stop();
    };
  }, [oktaAuth]);

  /**
   * Hook to get user name from okta
   */
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const user = await oktaAuth?.getUser();
        setUserName(`${user?.given_name ?? ''} ${user?.family_name ?? ''}`);
      } catch (error) {
        console.error(error);
      }
    };
    // If name is already set, do not fetch again
    if (authState?.isAuthenticated && !userName) {
      getUserInfo();
    }
  }, [authState?.isAuthenticated, oktaAuth, setUserName, userName]);

  return (
    <OktaContext.Provider value={{ authState, oktaAuth, setAuthState }}>
      {children}
    </OktaContext.Provider>
  );
};

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { encodeAsString, decodeString } from './stringJSONConversionHelpers';

type LocalStorageBackedState<S> = [S, Dispatch<SetStateAction<S>>];

// Extra signature for return saftey when initial value is provided
export function useLocalStorageBackedState<S = undefined>(args: {
  key: string;
  initialValue: S;
  persist?: boolean;
}): LocalStorageBackedState<S>;

// A custom hook for storing and updated content in a browsers local storage
export function useLocalStorageBackedState<S = undefined>({
  key,
  initialValue,
  persist = true
}: {
  key: string;
  initialValue?: S;
  persist?: boolean;
}): LocalStorageBackedState<S | undefined> {
  const [value, setValue] = useState<S | undefined>(() => {
    if (persist && typeof localStorage !== 'undefined') {
      const existingValue = localStorage?.getItem(key);
      if (existingValue) {
        try {
          return decodeString(existingValue);
        } catch {
          // Unparsable data in local storage
          localStorage.removeItem(key);
        }
      }
    }
    return initialValue;
  });

  useEffect(() => {
    if (persist) {
      const stringifiedValue = encodeAsString(value);
      try {
        decodeString(stringifiedValue);
        localStorage.setItem(key, stringifiedValue);
      } catch {
        // The value is not parsable and should not be set
      }
    }
  }, [key, value, persist]);

  return [value, setValue];
}

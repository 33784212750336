import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import { FilterContext } from './FilterContext';
import { FilterBy } from '../utils/useFilterState';
import { useOktaAuth } from './OktaContext';
import { useInterval } from '../utils/useInterval';
import { ErrorMessage } from './ErrorMessage';
import { Loading } from './Loading';

export interface APIResponse {
  vessels: Vessel[];
}

export interface Vessel {
  ActivityCode: number;
  AssetNumber: number;
  Beam?: number;
  CallSign?: string;
  CourseOverGround?: number;
  Draught?: number;
  DWT?: number;
  ETA?: number;
  Flag?: string;
  Fleet: string;
  GrossTonnage?: number;
  Heading?: number;
  IMONumber?: number;
  Latitude: number;
  LOA?: number;
  Longitude: number;
  MessageTimestamp?: number;
  MMSINumber?: number;
  NetTonnage?: number;
  NextPort: string;
  OfficialNumber?: string;
  OnBerth?: boolean;
  Ownership: string;
  PK: string;
  PreviousPortADT?: number;
  PreviousPortCountry?: string;
  PreviousPortName?: string;
  ReceivedDate?: number;
  SK: string;
  SpeedOverGround?: number;
  Status: VesselStatus;
  StatusDetail?: string;
  TEU: number;
  UpdatedAt: number;
  UpdatedBy: string;
  VesselClass: string;
  VesselGroup: string;
  VesselName: string;
  VesselOperator?: string;
  VesselOwner?: string;
  VesselType: string;
  VoyageNumber?: number;
  Width?: number;
}

export enum VesselStatus {
  Moving = 'Moving',
  Stopped = 'Stopped',
  OutOfService = 'Out Of Service',
  NA = 'N/A'
}

export interface VesselContextState {
  /**
   * All the vessels from the API
   */
  vessels: Vessel[];
  /**
   * Vessels based on the filters
   */
  filteredVessels: Vessel[];
}

export const VesselContext = createContext<VesselContextState>(
  {} as VesselContextState
);

interface Props {
  children: ReactNode;
}

const filterVesselsByGrouping = (
  theseVessels: Vessel[],
  toggleFilters: Partial<Record<FilterBy, Set<string>>>
) => {
  const filtered = theseVessels.filter((vessel) => {
    return Object.keys(toggleFilters).every((key) => {
      const k = key as FilterBy;
      const value = toggleFilters[k];

      if (toggleFilters[k]?.size !== 0 && value) {
        const filtersValueArray = Array.from(value);
        return filtersValueArray.includes(vessel[k] ?? '');
      }

      return true;
    });
  });

  return filtered;
};

const filterVesselsByFieldContains = (
  theseVessels: Vessel[],
  textFilters: Partial<Record<FilterBy, string>>
) => {
  const filtered = theseVessels.filter((vessel) => {
    return Object.keys(textFilters).every((key) => {
      const fieldName = key as FilterBy;
      const filterValue = textFilters[fieldName];

      if (filterValue && filterValue.length > 0) {
        const { [fieldName]: filterField } = vessel;
        return filterField
          ?.toLowerCase()
          .includes(filterValue?.trim().toLowerCase());
      }

      return true;
    });
  });

  return filtered;
};

export function VesselProvider({ children }: Props) {
  // Original copy from the data
  const [vessels, setVessels] = useState<Vessel[]>([]);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasSuccessfulFetch, setHasSuccessfulFetch] = useState<boolean>(false);
  // Copy when updating the data
  const [filteredVessels, setFilteredVessels] = useState<Vessel[]>([]);
  const { activeToggleFilters, activeTextFilters } = useContext(FilterContext);
  const { authState } = useOktaAuth();

  const callApi = useCallback(async () => {
    setIsFetching(true);
    try {
      // Only Make API Request if the user is logged in
      if (authState?.accessToken?.accessToken) {
        const { data } = await axios.get<APIResponse>(
          `${process.env.NEXT_PUBLIC_API_BASE}/v1/vessels`,
          {
            headers: {
              Authorization: `Bearer ${authState.accessToken.accessToken}`
            }
          }
        );
        setVessels(data.vessels);
        setFilteredVessels(data.vessels);
        setHasSuccessfulFetch(true);
      }
    } catch (error) {
      // TODO: Error handling
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [authState?.accessToken]);

  useEffect(() => {
    callApi();
  }, [authState, callApi]);

  // Poll API every 30s, The DB behind the API is updated every 60s
  useInterval(() => callApi(), 30000);

  /**
   * Performs client-side filtering of vessel data.  filterVesselsByGrouping
   * will filter vessels based on the checkboxes applied in the sidebar, and
   * filterVesselsByFieldContains will filter vessels by any text fields
   * defined using contains comparison logic.
   */
  const filterData = useCallback(() => {
    const filtered = filterVesselsByGrouping(vessels, activeToggleFilters);

    const extraFilteredVessels = filterVesselsByFieldContains(
      filtered,
      activeTextFilters
    );

    setFilteredVessels(extraFilteredVessels);
  }, [activeToggleFilters, activeTextFilters, vessels]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  let content = children;

  // If we have no successful fetches, and we are already authed, we are either fetching now, or there was an error fetching
  if (!hasSuccessfulFetch && !!authState?.accessToken?.accessToken) {
    if (isFetching) {
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <Loading />
        </div>
      );
    } else {
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <ErrorMessage />
        </div>
      );
    }
  }

  return (
    <VesselContext.Provider
      value={{
        vessels,
        filteredVessels
      }}
    >
      {content}
    </VesselContext.Provider>
  );
}
